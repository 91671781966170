import React from 'react'

export const ValidatorsIcon = () => (
  <svg viewBox="11 11 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.666016" width="44" height="44" rx="8" fill="url(#paint0_linear_5145_24902)"></rect>
    <path
      d="M26.791 19.3753L21.541 24.6253L18.916 22.0003M21.5621 12.5593L14.6739 16.4949C14.0506 16.8509 13.666 17.5136 13.666 18.2314V25.7585C13.666 26.482 14.0567 27.149 14.6878 27.5029L21.7388 31.4569C22.3414 31.7948 23.0759 31.7978 23.6812 31.4647L30.6302 27.6407C31.2691 27.2891 31.666 26.6177 31.666 25.8884V18.2474C31.666 17.5211 31.2723 16.8519 30.6374 16.4991L23.5256 12.5476C22.9141 12.2079 22.1695 12.2123 21.5621 12.5593Z"
      stroke="#1B202C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_5145_24902"
        x1="0.666016"
        y1="0"
        x2="44.666"
        y2="44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1B202C" stop-opacity="0.05"></stop>
        <stop offset="1" stop-color="#1B202C" stop-opacity="0"></stop>
      </linearGradient>
    </defs>
  </svg>
)
