import React from 'react'

export function ExplorerSymbol() {
  return (
    <svg viewBox="11 11 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5874_7536)">
        <rect width="44" height="44" rx="8" fill="url(#paint0_linear_5874_7536)"></rect>
        <path
          d="M21.9582 22L26.4791 19.5352M21.9582 22L17.4791 19.5352M21.9582 22L22 27M20.896 12.559L14.0078 16.4946C13.3846 16.8506 13 17.5133 13 18.2311V25.7582C13 26.4817 13.3907 27.1487 14.0218 27.5026L21.0728 31.4566C21.6754 31.7945 22.4099 31.7975 23.0152 31.4643L29.9642 27.6403C30.6031 27.2888 31 26.6174 31 25.8881V18.2471C31 17.5208 30.6063 16.8516 29.9714 16.4988L22.8596 12.5473C22.2481 12.2076 21.5035 12.212 20.896 12.559Z"
          stroke="#1B202C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </g>
      <defs>
        <linearGradient id="paint0_linear_5874_7536" x1="0" y1="0" x2="44" y2="44" gradientUnits="userSpaceOnUse">
          <stop stop-color="#1B202C" stop-opacity="0.05"></stop>
          <stop offset="1" stop-color="#1B202C" stop-opacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0_5874_7536">
          <rect width="44" height="44" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
